import React, { ReactNode, useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ExternalLink, IstariLogoColor, IstariLogoSecondary, Minus, Video } from "~/assets/svg";
import Popover from "~/components/Popover";
import { SideBarProps } from "~/types/option";
import { getEnv } from "~/utils/get_env";
import { RoutePathType } from "~/enums";

interface SubListProps {
  children: React.ReactNode | React.ReactNode[];
  isFloating?: boolean;
  open?: boolean;
}

const SubList = ({ children, isFloating, open }: SubListProps) => {
  if (isFloating) {
    return (
      <ul className="m-0 p-0">
        <li className="list-none whitespace-nowrap">{children}</li>
      </ul>
    );
  }
  return (
    <ul className={open ? "m-0 p-0 visible" : "m-0 p-0 hidden"}>
      <li className="list-none m-0 p-0">{children}</li>
    </ul>
  );
};

const SideBar = ({ activeNavItem, navLists, fullSize }: SideBarProps) => {
  const location = useLocation();

  const documentationURL = getEnv("VITE_DOCUMENTATION_URL") || "https://docs.dev.istari.app";
  const appsBaseUrl = getEnv("VITE_STATIC_APPS_BASE_URL") || "https://apps.dev.istari.app";
  const magicDocsUrl = getEnv("VITE_MAGIC_DOCS_URL") || `${appsBaseUrl}/magic-docs`;

  const getSubNavClassname = useCallback(
    (parent: string, child: string) => {
      if (location.search.includes(child)) {
        return "sidebar-nav-subitem active";
      }
      if (parent.includes("documentation")) {
        return "sidebar-nav-item-docs";
      }
      return "sidebar-nav-subitem";
    },
    [location.search],
  );

  const renderNavListItems = useMemo(
    () =>
      navLists?.map((list, i) => (
        <React.Fragment key={`nav-wrapper-${i}`}>
          {i !== 0 && <div className="w-full h-1px bg-gray-200" />}
          {list.map((item) => {
            const isDocs = item.value.includes("documentation");
            const isMagicDocs = item.value.includes("magic-docs");
            const childrenListItems = item.children?.map((child) => (
              <NavLink
                className={() => getSubNavClassname(item.value, child.value)}
                data-testid={`sidebar-nav-item-${child.value}`}
                key={child.value}
                target={isDocs ? "_blank" : undefined}
                to={isDocs ? child.value : `${item.value}?tab=${child.value}`}
                onClick={item.onClick}
              >
                {isDocs ? <Video /> : <Minus />}

                <span className="text-sm font-bold tracking-wider uppercase">{child.label}</span>
              </NavLink>
            ));

            const getNavToString = () => {
              if (isDocs) return documentationURL;
              if (isMagicDocs) return magicDocsUrl;
              return item.value;
            };

            return (
              <React.Fragment key={item.value}>
                {fullSize ? (
                  <>
                    <NavLink
                      className={({ isActive }) => (isActive ? "sidebar-nav-item active" : "sidebar-nav-item")}
                      data-testid={`sidebar-nav-item-${item.value}`}
                      tabIndex={0}
                      target={isDocs || isMagicDocs ? "_blank" : undefined}
                      to={getNavToString()!}
                      onClick={item.onClick}
                    >
                      {item.icon as ReactNode}

                      <span className="subtitle3">{item.label}</span>
                      {(isDocs || item.value === RoutePathType.MagicDocs) && (
                        <span className="items-center">
                          <ExternalLink width="14" height="14" data-testid="documentation-new-tab-icon" />
                        </span>
                      )}
                    </NavLink>

                    {
                      // if the item has children, render the sub-list
                      item.children && (
                        <SubList key={`${item.value}_subList`} open={isDocs ? true : activeNavItem === item.value}>
                          {childrenListItems}
                        </SubList>
                      )
                    }
                  </>
                ) : (
                  <Popover
                    placement="right-start"
                    popoverContent={
                      item.children ? (
                        <SubList key={`${item.value}_subList`} isFloating>
                          {childrenListItems}
                        </SubList>
                      ) : (
                        <NavLink className="self-center whitespace-nowrap subtitle3b text-gray-700" to={item.value}>
                          {item.label}
                        </NavLink>
                      )
                    }
                  >
                    <NavLink
                      aria-label={item.label}
                      className={({ isActive }) =>
                        isActive ? "w-55px sidebar-nav-item active" : "w-55px sidebar-nav-item"
                      }
                      data-testid={`sidebar-nav-item-${item.value}`}
                      tabIndex={0}
                      target={isDocs || isMagicDocs ? "_blank" : undefined}
                      to={getNavToString()!}
                      onClick={item.onClick}
                    >
                      {item.icon as ReactNode}
                    </NavLink>
                  </Popover>
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )),
    [navLists, activeNavItem, documentationURL, getSubNavClassname, fullSize],
  );

  return (
    <div
      className={`fixed ${
        getEnv("VITE_ITAR") === "true" ? "top-33px" : "top-0"
      } ${fullSize ? `w-210px` : `w-60px`} h-full bg-gray-100`}
    >
      <div className="flex items-center justify-center h-15">
        <IstariLogoColor data-testid="sidebar-top-logo" width={fullSize ? "68px" : "25px"} />
      </div>

      <div className="w-full h-1px bg-gray-200" />

      <nav id="sidebar" className={`${fullSize ? `w-210px` : "w-68px"} pt-24px px-8px box-border`}>
        {renderNavListItems}
      </nav>

      {fullSize ? (
        <div
          id="app-logo-and-version"
          data-testid="sidebar-grey-logo-and-version"
          className="absolute bottom-4 right-1/2 translate-x-1/2 color-gray-700"
        >
          <div className="flex items-center justify-center color-gray-300">
            <IstariLogoSecondary />
          </div>

          <span className="text-sm">Version {getEnv("VITE_RELEASE_VERSION")}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SideBar;
