import { AccessRelationship } from "@istari/istari-client/dist/src/openapi";
import React, { memo } from "react";
import { UseMutationResult } from "@tanstack/react-query";

import { useGetModelAccessById } from "../../api/useModels";
import { useListOrgUsers } from "../../api/useUsers";
import AuthenticationStore from "../../auth";
import { LoadingSpinner } from "../../assets/svg";
import { UserGrantType } from "../../enums";
import { getCanUserRemove } from "../../permissions";

import Modal from "../Modal";

interface RemoveModalProps {
  isOpen: boolean;
  modelId: string;
  name: string;
  removeFunc: UseMutationResult<any, unknown, any, unknown>;
  title: string;
  onHide: () => void;
}

const RemoveModal = memo(({ isOpen, modelId, name, removeFunc, title, onHide }: RemoveModalProps) => {
  const { data: modelAccessList, isError, isLoading: isLoadingModelAccess } = useGetModelAccessById(modelId, true);
  const { data: orgUsersData } = useListOrgUsers();
  const AS = AuthenticationStore();
  const { userId: currentUserSubId } = AS.getCurrentUser();
  const currentUser = orgUsersData?.find((orgUser) => orgUser.providerUserId === currentUserSubId);
  const currentUserModelPermission = modelAccessList?.find(
    (userGrant: AccessRelationship) => userGrant.subjectId === currentUser?.id,
  )?.relation as UserGrantType;

  const content = (
    <div className="items-center justify-between w-full">
      <div className="color-gray-700">
        Are you sure you want to remove <strong>{name}</strong>?
      </div>
    </div>
  );

  const getContent = () => {
    if (isLoadingModelAccess) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner width="30px" height="30px" />
        </div>
      );
    }
    if (isError || !getCanUserRemove(currentUserModelPermission)) {
      return <div className="color-gray-700">You do not have permission to remove this file.</div>;
    }

    return content;
  };

  const getLabel = () => {
    if (isLoadingModelAccess) {
      return "Loading";
    }
    if (isError || !getCanUserRemove(currentUserModelPermission)) {
      return "OK";
    }
    return "Remove";
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      content={getContent()}
      includeCancelButton={getCanUserRemove(currentUserModelPermission)}
      actionButton={{
        dataTestId: `${title}-remove-btn`,
        disabled: removeFunc.isLoading,
        label: getLabel(),
        loading: removeFunc.isLoading,
        variant: getCanUserRemove(currentUserModelPermission) ? "danger" : "primary",
        clickHandler: getCanUserRemove(currentUserModelPermission) ? () => removeFunc.mutate({ modelId }) : onHide,
      }}
      onHide={onHide}
    />
  );
});

export default RemoveModal;
