import React, { useCallback, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { LogOut, User, UserEmpty } from "~/assets/svg";
import { RoutePathType, SettingsSubpage } from "~/enums";
import Menu from "../../../Menu";
import Modal from "../../../Modal";

const UserMenu = () => {
  const auth = useAuth();
  const { user } = auth;
  const displayName = `${user?.profile.given_name} ${user?.profile.family_name}`;

  const [logoutConfirmPopupOpen, setLogoutConfirmPopupOpen] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  const navigate = useNavigate();
  const menuItems = [
    {
      label: "Profile Settings",
      icon: <UserEmpty width="20" height="20" />,
      value: "profile-settings",
    },
    {
      label: "Log Out",
      icon: <LogOut width="20" height="20" />,
      value: "logout",
    },
  ];

  const logoutConfirmHandler = useCallback(async () => {
    try {
      setIsLeaving(true);
      auth.removeUser();
      auth.signoutRedirect();
    } catch (error) {
      setIsLeaving(false);
    }
    setIsLeaving(false);
  }, [auth]);

  const menuItemClickHandler = useCallback(
    (itemValue: string) => {
      switch (itemValue) {
        case "logout": {
          setLogoutConfirmPopupOpen(true);
          break;
        }
        case "profile-settings": {
          navigate({
            pathname: `${RoutePathType.Root}${RoutePathType.Settings}`,
            search: `?tab=${SettingsSubpage.PROFILE_SETTINGS}`,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    [navigate],
  );

  const userProfileMenu = (
    <>
      <span>{displayName}</span>
      <span className="ml-3 flex text-center justify-center p-3px rounded-full bg-gray-300">
        <User data-testid="PersonIcon" width="30" height="30" color="#fff" />
      </span>
    </>
  );

  const logoutContent = (
    <Modal
      open={logoutConfirmPopupOpen}
      title="Confirm logout"
      content={<div>Are you sure you want to log out?</div>}
      includeCancelButton
      actionButton={{
        dataTestId: "logout-confirm-button",
        disabled: isLeaving,
        label: "Confirm",
        loading: isLeaving,
        variant: "primary",
        clickHandler: logoutConfirmHandler,
      }}
      onHide={() => setLogoutConfirmPopupOpen(false)}
    />
  );

  return (
    <>
      <div className="flex min-w-25 justify-center" data-testid="header-user-profile-menu-container">
        <Menu
          ariaLabel="user profile menu"
          options={menuItems}
          triggerLabelIcon={userProfileMenu}
          onMenuItemClick={menuItemClickHandler}
        />
      </div>
      {logoutContent}
    </>
  );
};

export default UserMenu;
