import { AccessRelationship } from "@istari/istari-client/dist/src/openapi";
import React, { memo, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";

import { useGetModelAccessById } from "../../api/useModels";
import { useListOrgUsers } from "../../api/useUsers";
import AuthenticationStore from "../../auth";
import { LoadingSpinner } from "../../assets/svg";
import { UserGrantType } from "../../enums";
import { getCanUserEdit } from "../../permissions";

import Modal from "../Modal";
import TextInput from "../TextInput";

interface RenameModalProps {
  file: any;
  isOpen: boolean;
  modelId: string;
  name: string;
  renameFunc: UseMutationResult<any, unknown, any, unknown>;
  title: string;
  onHide: () => void;
}

const RenameModal = memo(({ file, isOpen, modelId, name, renameFunc, title, onHide }: RenameModalProps) => {
  const [newName, setNewName] = useState(name);

  const { data: modelAccessList, isError, isLoading: isLoadingModelAccess } = useGetModelAccessById(modelId, true);
  const { data: orgUsersData } = useListOrgUsers();
  const AS = AuthenticationStore();
  const { userId: currentUserSubId } = AS.getCurrentUser();
  const currentUser = orgUsersData?.find((orgUser) => orgUser.providerUserId === currentUserSubId);
  const currentUserModelPermission = modelAccessList?.find(
    (userGrant: AccessRelationship) => userGrant.subjectId === currentUser?.id,
  )?.relation as UserGrantType;

  const content = (
    <div className="items-center justify-between w-full">
      <div className="color-gray-700">
        <div className="mb-5px">Name</div>
        <TextInput
          ariaLabel="new name"
          dataTestId="rename-modal-name-input"
          value={newName}
          onChange={(value: string) => setNewName(value)}
        />
      </div>
    </div>
  );

  const getContent = () => {
    if (isLoadingModelAccess) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner width="30px" height="30px" />
        </div>
      );
    }
    if (isError || !getCanUserEdit(currentUserModelPermission)) {
      return <div className="color-gray-700">You do not have permission to edit this file name.</div>;
    }

    return content;
  };

  const getLabel = () => {
    if (isLoadingModelAccess) {
      return "Loading";
    }
    if (isError || !getCanUserEdit(currentUserModelPermission)) {
      return "OK";
    }
    return "Update";
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      content={getContent()}
      includeCancelButton
      actionButton={{
        dataTestId: `${title}-save-btn`,
        disabled: renameFunc.isLoading || !newName,
        label: getLabel(),
        loading: renameFunc.isLoading,
        variant: "primary",
        clickHandler: getCanUserEdit(currentUserModelPermission)
          ? () => renameFunc.mutate({ file, name: newName })
          : onHide,
      }}
      onHide={onHide}
    />
  );
});

export default RenameModal;
