import React from "react";

export type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "ternary"
  | "outlined"
  | "transparent"
  | "danger"
  | "warning";

type ButtonProps = {
  dataTestId?: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  label: string;
  small?: boolean;
  startIcon?: React.ReactNode;
  variant: ButtonVariant;
  onClick: () => void;
};

const Button = ({
  dataTestId,
  disabled = false,
  endIcon,
  fullWidth,
  label,
  small = false,
  startIcon,
  variant = "default",
  onClick,
}: ButtonProps) => {
  const buttonStyle =
    "flex items-center justify-center gap-x-8px px-12px py-8px rounded border-none cursor-pointer whitespace-pre";
  const buttonHeight = small ? "h-8" : "h-10";
  const fullWidthStyle = fullWidth ? "w-full" : "";
  const textSize = small ? "text-sm" : "text-base";
  const variantStyles = {
    default: "color-white-0 bg-gray-800 hover:bg-gray-900",
    primary: "color-white-0 bg-primary-400 hover:bg-primary-500",
    secondary:
      "border border-solid border-width-2px border-primary-500 bg-white-0 color-primary-500 hover:bg-primary-50",
    ternary: "color-gray-800 bg-gray-100 hover:bg-gray-50",
    outlined: "color-gray-700 bg-white-0 border border-solid border-gray-300 hover:bg-primary-50",
    transparent: "color-primary-500 bg-transparent hover:bg-gray-50",
    danger: "color-white-0 bg-red-400 hover:bg-red-300",
    warning: "color-red-400 bg-red-100 hover:bg-red-50",
  };

  return (
    <button
      className={`${buttonStyle} ${fullWidthStyle} ${buttonHeight} ${textSize} ${variantStyles[variant]}`}
      data-testid={dataTestId}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {startIcon && startIcon}
      {label}
      {endIcon && endIcon}
    </button>
  );
};

export default Button;
