export const getFileNameAndExtension = (fileName: string | undefined) => {
  if (!fileName) {
    return null;
  }
  const trimInd = fileName.lastIndexOf(".") ?? fileName.length;
  return {
    extension: fileName.substring(trimInd + 1, fileName.length),
    name: fileName.substring(0, trimInd),
  };
};

export const getLiteralModelName = (extension: string | undefined): string | undefined => {
  switch (extension) {
    case "catpart":
    case "catproduct":
      return "Catia";
    case "mdzip":
      return "Cameo";
    case "xlsx":
      return "Excel";
    default:
      return extension;
  }
};

export const getExtractableFileType = (extension: string): string => {
  switch (extension) {
    case "catpart":
    case "catproduct":
      return "catia";
    case "docx":
      return "word";
    case "mdzip":
      return "cameo";
    case "xlsx":
    case "csv":
      return "excel";
    default:
      return extension;
  }
};
