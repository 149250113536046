import React, { ChangeEvent, memo } from "react";

interface TextAreaProps {
  ariaLabel?: string;
  dataTestId?: string;
  disabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (_value: string) => void;
}

const TextArea = memo(
  ({
    ariaLabel,
    dataTestId,
    disabled = false,
    isError = false,
    placeholder = "",
    value = "",
    onChange,
  }: TextAreaProps) => {
    const borderColor = isError ? "border-red-400" : "border-gray-300";

    return (
      <div className="mt-5px">
        <textarea
          aria-label={ariaLabel}
          className={`p-8px w-[90%] border rounded text-gray-700 ${borderColor}`}
          data-testid={dataTestId}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value)}
        />
      </div>
    );
  },
);

export default TextArea;
