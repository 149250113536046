import { authConfig } from "../auth/zitadel-config";
import createService from "./createService";

export const adminService = createService({
  url: `${authConfig.authority}/management/v1`,
});

export const authService = createService({
  url: `${authConfig.authority}/oauth/v2`,
});

export const profileService = createService({
  url: `${authConfig.authority}/auth/v1`,
});
