import React, { memo, useState } from "react";

import { CaretDown } from "../../assets/svg";
import { UserGrantType } from "../../enums";
import { accessOptions } from "../../permissions";

import Menu from "../Menu";

interface PermissionMenuProps {
  currentUserAccess: string;
  defaultUserAccess: string | undefined;
  selectedItemIndex: number;
  onChangeAccess: (_value: UserGrantType) => void;
  onRemoveUserAccess?: () => void;
}

const PermissionMenu = memo(
  ({
    currentUserAccess,
    defaultUserAccess = "",
    selectedItemIndex,
    onChangeAccess,
    onRemoveUserAccess,
  }: PermissionMenuProps) => {
    const [selectedAccess, setSelectedAccess] = useState<string>(defaultUserAccess);

    const getAccessBasedOnRole = (access: string) => {
      // approver or viewer will not see the model share option
      if (access === UserGrantType.editor) {
        return accessOptions.filter((permission) => permission.value !== UserGrantType.administrator); // add remove if editor is allowed to
      }
      return [...accessOptions, { label: "remove", value: "remove" }];
    };

    const permissionOptions = getAccessBasedOnRole(currentUserAccess);

    const optionMenuClick = (optionLabel: string) => {
      switch (optionLabel) {
        case "remove": {
          onRemoveUserAccess?.();
          break;
        }
        default: {
          const selectedPermissionId = permissionOptions.find((item) => item.value === optionLabel)
            ?.value as UserGrantType;
          onChangeAccess(selectedPermissionId!);
          setSelectedAccess(selectedPermissionId!);
        }
      }
    };

    return (
      <Menu
        ariaLabel="share modal permission menu"
        dataTestId="access-menu-trigger"
        options={permissionOptions}
        selectedItemIndex={selectedItemIndex}
        triggerLabelIcon={
          <div className="flex items-center justify-center gap-5px color-primary-400 subtitle3b capitalize">
            <span>{selectedAccess}</span>
            <CaretDown width="18px" height="18px" />
          </div>
        }
        onMenuItemClick={(optionName) => optionMenuClick(optionName)}
      />
    );
  },
);

export default PermissionMenu;
