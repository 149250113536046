import { authConfig } from "./zitadel-config";

interface AccessToken {
  access_token: string;
  id_token: string;
  profile: { [key: string]: unknown };
  refresh_token: string;
  token_type: "Bearer";
}

interface ZitadelUserState {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: null | string;
}

interface ZitadelUserProfile {
  aud: Array<string>;
  c_hash: string;
  client_id: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  locale: string | null;
  name: string;
  preferred_username: string;
  sub: string;
  updated_at: number;
}

interface ZitadelUserData {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: ZitadelUserProfile;
  refresh_token: string;
  scope: string;
  state?: ZitadelUserState;
  token_type: "Bearer";
}

export const istariUserLocalStorageKey = "istari-user";

const AuthenticationStore = () => {
  const zitadelStoreKey = `oidc.user:${authConfig.authority}:${authConfig.client_id}`;
  const zitadelData: ZitadelUserData = JSON.parse(window.localStorage.getItem(zitadelStoreKey) as string);

  const userStr = window.localStorage.getItem(istariUserLocalStorageKey);
  const user = userStr ? JSON.parse(userStr) : {};

  function clearSessionToken() {
    window.localStorage.removeItem(zitadelStoreKey);
  }

  function getAccessToken(): string | undefined {
    return zitadelData?.access_token;
  }

  function getAccessTokenExpires(): number | null {
    return zitadelData?.expires_at;
  }

  function getCurrentUser(): { userId: string | undefined; userEmail: string | undefined } {
    const userId = user?.profile?.sub;
    const userEmail = user?.profile?.email;

    return {
      userId,
      userEmail,
    };
  }

  function getCurrentUserObject(): ZitadelUserData {
    return user;
  }

  function getRedirectPath(): string | null {
    const { pathname = "", search = "" } = user.state || {};
    if (pathname) {
      return pathname + search;
    }
    return null;
  }

  function getRefreshToken(): string {
    return zitadelData?.refresh_token;
  }

  function saveAccessToken(accessToken: AccessToken) {
    const { access_token, id_token, refresh_token } = accessToken;

    const newUserStr = {
      ...user,
      id_token,
      access_token,
      refresh_token,
    };
    window.localStorage.setItem(istariUserLocalStorageKey, JSON.stringify(newUserStr));
  }

  return {
    clearSessionToken,
    getAccessToken,
    getAccessTokenExpires,
    getCurrentUser,
    getCurrentUserObject,
    getRedirectPath,
    getRefreshToken,
    saveAccessToken,
  };
};

export default AuthenticationStore;
